import { ImageDataLike } from 'gatsby-plugin-image';

export enum ProductType {
  Level = 'Level',
  Deposit = 'Deposit',
  SecondPayment = 'Second_Payment',
}

export enum DataProductType {
  Level = 'level',
  Deposit = 'deposit',
  SecondPayment = 'second_payment',
}

export interface IPricingList {
  EUR: number;
  GBP: number;
  USD: number;
  NSZ: number;
  AUD: number;
}

export interface IEligibility {
  Title: string;
}

export interface ILabel {
  Title: string;
}

export interface IProduct {
  id: number;
  Type: ProductType;
  Title: string;
  Content: string;
  Cost: IPricingList;
  Identifier: string;
}

export interface IProgramme {
  Title: string;
  strapiId: number;
  Pipedrive_ID: number;
  Cost: IPricingList;
  Deposit: IPricingList;
  Duration: string;
  Country: ILabel;
  Category: ILabel;
  Eligibility: IEligibility[];
  Products: IProduct[];
  Key_Visual: ImageDataLike;
  termsUrl?: string;
}

export interface IDataProduct {
  id: string;
  identifier: string;
  name: string;
  description: string;
  type: DataProductType;
  prices: IPricingList;
}
