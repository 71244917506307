/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { siteMetadata } from './gatsby-config';

import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './src/hooks/useAuth';
import { MyBunacProvider } from './src/hooks/useMyBunac';
import { ApiProvider } from './src/hooks/useApi';
import { DealsProvider } from './src/hooks/useDeals';
import { LocationProvider } from './src/hooks/useLocation';
import { ProfileProvider } from './src/hooks/useProfile';
import { ApplicationProvider } from './src/hooks/useApplication';
import { CheckoutProvider } from './src/hooks/useCheckout';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './src/styles/global.css';

export const wrapRootElement = ({ element, ...props }) => (
  <Auth0Provider
    domain={siteMetadata.Auth.DOMAIN}
    clientId={siteMetadata.Auth.CLIENT_ID}
    redirectUri={`${window.location.origin}/mybunac`}
    cacheLocation="localstorage"
  >
    <AuthProvider {...props}>
      <LocationProvider {...props}>
        <MyBunacProvider {...props}>
          <ApiProvider {...props}>
            <ProfileProvider {...props}>
              <DealsProvider {...props}>
                <ApplicationProvider {...props}>
                  <CheckoutProvider {...props}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {element}
                    </MuiPickersUtilsProvider>
                  </CheckoutProvider>
                </ApplicationProvider>
              </DealsProvider>
            </ProfileProvider>
          </ApiProvider>
        </MyBunacProvider>
      </LocationProvider>
    </AuthProvider>
  </Auth0Provider>
);
