export default currency => ({
  id,
  name,
  prices,
  description,
  type,
  assigned,
  paid,
  ...rest
}) => {
  const price = prices[currency];

  return {
    ...rest,
    id,
    title: name,
    description,
    price,
    currency,
    selected: assigned,
    paid,
    type,
  };
};
