import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthContext = React.createContext({
  isAuthenticated: false,
  user: {},
  token: '',
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children, ...initOptions }) => {
  const {
    user,
    getIdTokenClaims,
    loginWithRedirect: auth0Login,
    logout: auth0Logout,
    isAuthenticated: isAut0hAuthenticated,
  } = useAuth0();
  const [token, setToken] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    updateToken();
  }, [user, isAut0hAuthenticated]);

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  const updateToken = async () => {
    const claims = await getIdTokenClaims();
    setToken(claims && claims.__raw ? claims.__raw : undefined);
  };

  const login = async (params = {}) => {
    auth0Login();
  };

  const logout = async () => {
    auth0Logout({ returnTo: window.location.origin });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
