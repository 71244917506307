import {
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import React, { FunctionComponent, useCallback } from 'react';
import { FormTitle } from './FormTitle';

export enum PaymentChoices {
  DEPOSIT = 'deposit',
  FULL = 'full',
}

interface PaymentChoiceProps {
  choice: PaymentChoices;
  total: string;
  deposit: string;
  onChange: (choice: PaymentChoices) => void;
}

const PaymentChoice: FunctionComponent<PaymentChoiceProps> = ({
  choice,
  onChange,
  total,
  deposit,
}) => {
  const onDepositSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked
        ? PaymentChoices.DEPOSIT
        : PaymentChoices.FULL;
      onChange(value);
    },
    [onChange]
  );

  const onFullSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked
        ? PaymentChoices.FULL
        : PaymentChoices.DEPOSIT;
      onChange(value);
    },
    [onChange]
  );

  return (
    <>
      <FormTitle>Select what you would like to pay now:</FormTitle>
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={choice === PaymentChoices.DEPOSIT}
                onChange={onDepositSelected}
                name={PaymentChoices.DEPOSIT}
              />
            }
            label={`Deposit - ${deposit}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={choice === PaymentChoices.FULL}
                onChange={onFullSelected}
                name={PaymentChoices.FULL}
              />
            }
            label={`Full Trip - ${total}`}
          />
        </FormGroup>
      </FormControl>
    </>
  );
};

export { PaymentChoice };
