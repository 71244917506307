import { siteMetadata } from '../../gatsby-config';
import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from './useAuth';
import { useApi } from './useApi';
import mapProductData from './lib/mapProductData';
import mapDealData from './lib/mapDealData';

export const ApplicationContext = React.createContext({
  application: {},
  load: () => {},
  set: () => {},
  upload: () => {},
  finishActivity: () => {},
  isLoading: false,
  refreshApplication: () => {},
  refreshUntilStageChanges: () => {},
  isWaitingForNextStage: false,
  confirmPayment: async () => {},
  refundPayment: async () => {},
});
export const useApplication = () => useContext(ApplicationContext);

export const ApplicationProvider = ({ children }) => {
  const { request } = useApi();
  const { token, isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingForNextStage, setIsWaitingForNextStage] = useState(false);
  const [application, setApplication] = useState(null);
  const [programmeLevel, setProgrammeLevel] = useState();
  const [availableProducts, setAvailableProducts] = useState([]);
  const [availableProgrammeLevels, setAvailableProgrammeLelves] = useState([]);
  const [availableAddOns, setAvailableAddons] = useState([]);
  const backendUrl = siteMetadata.Auth.BACKEND_URL.replace(/\/+$/, '');
  const [applicationId, setApplicationId] = useState();

  const fetchApplication = async () => {
    let fetchedApplication = null;
    await setIsLoading(true);
    try {
      const { deals } = await request({
        path: `/deals/${applicationId}`,
        method: 'GET',
      });

      if (!deals || !deals.length) {
        throw new Error(`No deal found with id ${applicationId}`);
      }
      const mappedDeals = deals.map(mapDealData);
      fetchedApplication = mappedDeals[0];
      setApplication(fetchedApplication);
    } catch (error) {
      console.log('Could not load deal data: ' + error);
    }
    await setIsLoading(false);
    return fetchedApplication;
  };

  const refreshApplication = async () => {
    if (applicationId) {
      return fetchApplication();
    }

    return false;
  };

  const refreshUntilStageChanges = async () => {
    const sleep = async ({ ms }) =>
      new Promise(resolve => setTimeout(resolve, ms));
    const refreshDeal = async () => {
      if (!window.location.pathname.includes('/mybunac/application')) {
        return true;
      }
      const refreshedApplication = await refreshApplication();
      if (
        refreshedApplication.currentStage.label ===
        application.currentStage.label
      ) {
        await sleep({ ms: 2000 });
        await refreshDeal();
        return true;
      }
    };

    await setIsWaitingForNextStage(true);
    await refreshDeal();
    await setIsWaitingForNextStage(false);
  };

  const updateProducts = async () => {
    if (!applicationId || !application) {
      return setAvailableProducts([]);
    }

    const res = await window.fetch(
      `${backendUrl}/deals/${applicationId}/products`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const json = await res.json();
    setAvailableProducts(
      json.products.map(mapProductData(application.currency))
    );
  };

  const setAddOn = async product => {
    await assignProduct(product);
  };

  const unsetAddOn = async product => {
    await unassignProduct(product);
  };

  const assignProgrammeLevel = async product => {
    await assignProduct(product);
    await updateProducts();
  };

  const assignProduct = async ({ id, quantity }) => {
    const body = {
      productid: id,
      currency: 'EUR',
    };

    if (quantity) {
      body.quantity = quantity;
    }

    try {
      await window.fetch(`${backendUrl}/deals/${applicationId}/products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      return true;
    } catch (err) {
      return false;
    }
  };

  const unassignProduct = async ({ id }) => {
    try {
      await window.fetch(
        `${backendUrl}/deals/${applicationId}/products/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return true;
    } catch (err) {
      return false;
    }
  };

  const set = ({ id, key, value }) => {
    return window
      .fetch(`${backendUrl}/api/deals/${id}/attributes`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key, value }),
      })
      .then(res => res.json());
  };

  const upload = ({ id, activityId, note, file }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('activityid', activityId);
    formData.append('dealid', id);
    formData.append('note', note);

    return window
      .fetch(`${backendUrl}/deals/${id}/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
      .then(res => res.json());
  };

  const finishActivity = id => {
    return window.fetch(`${backendUrl}/api/activities/${id}/finish`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const confirmPayment = async ({
    type,
    amount,
    currency,
    products,
    paymentId,
  }) => {
    try {
      await setIsLoading(true);
      await request({
        path: `/deals/${applicationId}/pay`,
        method: 'POST',
        params: {
          validatePaypal: false,
          type,
          amount,
          currency: application.currency,
          paymentid: paymentId,
          productids: JSON.stringify(products.map(product => product.id)),
        },
      });
    } finally {
      await setIsLoading(false);
    }
  };

  const refundPayment = async ({
    type,
    amount,
    currency,
    products,
    paymentId,
  }) => {
    try {
      await setIsLoading(true);
      await request({
        path: `/deals/${applicationId}/refund`,
        method: 'POST',
        params: {
          validatePaypal: false,
          type,
          amount,
          currency: application.currency,
          paymentid: paymentId,
          productids: JSON.stringify(products.map(product => product.id)),
        },
      });
    } finally {
      await setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && applicationId) {
      fetchApplication({ dealId: applicationId });
    }
  }, [isAuthenticated, applicationId]);

  useEffect(() => {
    updateProducts();
  }, [application]);

  useEffect(() => {
    // validate that it changed
    if (programmeLevel && programmeLevel.id) {
      assignProgrammeLevel(programmeLevel);
    }
  }, [programmeLevel]);

  useEffect(() => {
    setAvailableAddons(
      availableProducts.filter(({ type }) => type === 'addon')
    );
    setAvailableProgrammeLelves(
      availableProducts.filter(({ type }) => type === 'level')
    );
  }, [availableProducts]);

  return (
    <ApplicationContext.Provider
      value={{
        application,
        setApplicationId,
        set,
        upload,
        finishActivity,
        refreshApplication,
        refreshUntilStageChanges,

        availableProgrammeLevels,
        setProgrammeLevel,
        programmeLevel,

        availableAddOns,
        setAddOn,
        unsetAddOn,

        updateProducts,

        isLoading,
        isWaitingForNextStage,

        confirmPayment,
        refundPayment,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
