// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-blog-category-js": () => import("./../../../src/BlogCategory.js" /* webpackChunkName: "component---src-blog-category-js" */),
  "component---src-blog-tsx": () => import("./../../../src/Blog.tsx" /* webpackChunkName: "component---src-blog-tsx" */),
  "component---src-book-js": () => import("./../../../src/Book.js" /* webpackChunkName: "component---src-book-js" */),
  "component---src-categories-js": () => import("./../../../src/Categories.js" /* webpackChunkName: "component---src-categories-js" */),
  "component---src-category-js": () => import("./../../../src/Category.js" /* webpackChunkName: "component---src-category-js" */),
  "component---src-country-tsx": () => import("./../../../src/Country.tsx" /* webpackChunkName: "component---src-country-tsx" */),
  "component---src-destination-tsx": () => import("./../../../src/Destination.tsx" /* webpackChunkName: "component---src-destination-tsx" */),
  "component---src-page-tsx": () => import("./../../../src/Page.tsx" /* webpackChunkName: "component---src-page-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authentification-required-js": () => import("./../../../src/pages/authentification-required.js" /* webpackChunkName: "component---src-pages-authentification-required-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-book-now-js": () => import("./../../../src/pages/book-now.js" /* webpackChunkName: "component---src-pages-book-now-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-forms-arrival-date-js": () => import("./../../../src/pages/forms/arrival-date.js" /* webpackChunkName: "component---src-pages-forms-arrival-date-js" */),
  "component---src-pages-forms-ask-a-question-js": () => import("./../../../src/pages/forms/ask-a-question.js" /* webpackChunkName: "component---src-pages-forms-ask-a-question-js" */),
  "component---src-pages-forms-best-scholarship-js": () => import("./../../../src/pages/forms/best-scholarship.js" /* webpackChunkName: "component---src-pages-forms-best-scholarship-js" */),
  "component---src-pages-forms-call-back-request-js": () => import("./../../../src/pages/forms/call-back-request.js" /* webpackChunkName: "component---src-pages-forms-call-back-request-js" */),
  "component---src-pages-forms-employer-invite-js": () => import("./../../../src/pages/forms/employer-invite.js" /* webpackChunkName: "component---src-pages-forms-employer-invite-js" */),
  "component---src-pages-forms-employer-preference-js": () => import("./../../../src/pages/forms/employer-preference.js" /* webpackChunkName: "component---src-pages-forms-employer-preference-js" */),
  "component---src-pages-forms-flight-js": () => import("./../../../src/pages/forms/flight.js" /* webpackChunkName: "component---src-pages-forms-flight-js" */),
  "component---src-pages-forms-full-application-js": () => import("./../../../src/pages/forms/full-application.js" /* webpackChunkName: "component---src-pages-forms-full-application-js" */),
  "component---src-pages-forms-insurance-quote-js": () => import("./../../../src/pages/forms/insurance-quote.js" /* webpackChunkName: "component---src-pages-forms-insurance-quote-js" */),
  "component---src-pages-forms-orientation-date-js": () => import("./../../../src/pages/forms/orientation-date.js" /* webpackChunkName: "component---src-pages-forms-orientation-date-js" */),
  "component---src-pages-forms-skype-interview-js": () => import("./../../../src/pages/forms/skype-interview.js" /* webpackChunkName: "component---src-pages-forms-skype-interview-js" */),
  "component---src-pages-forms-soft-application-js": () => import("./../../../src/pages/forms/soft-application.js" /* webpackChunkName: "component---src-pages-forms-soft-application-js" */),
  "component---src-pages-forms-summer-camp-fair-js": () => import("./../../../src/pages/forms/summer-camp-fair.js" /* webpackChunkName: "component---src-pages-forms-summer-camp-fair-js" */),
  "component---src-pages-forms-thank-you-js": () => import("./../../../src/pages/forms/thank-you.js" /* webpackChunkName: "component---src-pages-forms-thank-you-js" */),
  "component---src-pages-forms-visa-assessment-js": () => import("./../../../src/pages/forms/visa-assessment.js" /* webpackChunkName: "component---src-pages-forms-visa-assessment-js" */),
  "component---src-pages-forms-work-america-online-fair-js": () => import("./../../../src/pages/forms/work-america-online-fair.js" /* webpackChunkName: "component---src-pages-forms-work-america-online-fair-js" */),
  "component---src-pages-forms-work-canada-job-fair-js": () => import("./../../../src/pages/forms/work-canada-job-fair.js" /* webpackChunkName: "component---src-pages-forms-work-canada-job-fair-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mybunac-addon-js": () => import("./../../../src/pages/mybunac/addon.js" /* webpackChunkName: "component---src-pages-mybunac-addon-js" */),
  "component---src-pages-mybunac-application-js": () => import("./../../../src/pages/mybunac/application.js" /* webpackChunkName: "component---src-pages-mybunac-application-js" */),
  "component---src-pages-mybunac-index-js": () => import("./../../../src/pages/mybunac/index.js" /* webpackChunkName: "component---src-pages-mybunac-index-js" */),
  "component---src-pages-mybunac-information-js": () => import("./../../../src/pages/mybunac/information.js" /* webpackChunkName: "component---src-pages-mybunac-information-js" */),
  "component---src-pages-mybunac-logout-js": () => import("./../../../src/pages/mybunac/logout.js" /* webpackChunkName: "component---src-pages-mybunac-logout-js" */),
  "component---src-pages-mybunac-payment-js": () => import("./../../../src/pages/mybunac/payment.js" /* webpackChunkName: "component---src-pages-mybunac-payment-js" */),
  "component---src-pages-mybunac-payment-recording-js": () => import("./../../../src/pages/mybunac/payment-recording.js" /* webpackChunkName: "component---src-pages-mybunac-payment-recording-js" */),
  "component---src-pages-mybunac-profile-js": () => import("./../../../src/pages/mybunac/profile.js" /* webpackChunkName: "component---src-pages-mybunac-profile-js" */),
  "component---src-pages-mybunac-refund-recording-js": () => import("./../../../src/pages/mybunac/refund-recording.js" /* webpackChunkName: "component---src-pages-mybunac-refund-recording-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-visa-assessment-js": () => import("./../../../src/pages/visa-assessment.js" /* webpackChunkName: "component---src-pages-visa-assessment-js" */),
  "component---src-product-js": () => import("./../../../src/Product.js" /* webpackChunkName: "component---src-product-js" */),
  "component---src-programme-blog-js": () => import("./../../../src/ProgrammeBlog.js" /* webpackChunkName: "component---src-programme-blog-js" */),
  "component---src-programme-js": () => import("./../../../src/Programme.js" /* webpackChunkName: "component---src-programme-js" */),
  "component---src-programme-v-2-tsx": () => import("./../../../src/ProgrammeV2.tsx" /* webpackChunkName: "component---src-programme-v-2-tsx" */),
  "component---src-terms-conditions-js": () => import("./../../../src/TermsConditions.js" /* webpackChunkName: "component---src-terms-conditions-js" */),
  "component---src-terms-conditions-v-2-tsx": () => import("./../../../src/TermsConditionsV2.tsx" /* webpackChunkName: "component---src-terms-conditions-v-2-tsx" */)
}

