import { siteMetadata } from '../../gatsby-config';
import React, { useEffect, useContext } from 'react';
import createPersistedState from 'use-persisted-state';
import { Currencies } from '../components/config/currency';

const useCurrency = createPersistedState('currency');

const useCountry = createPersistedState('country');

const COUNTRY_TO_CURRENCY_MAPPING = {
  'AT': 'EUR',
  'AU': 'AUD',
  'BE': 'EUR',
  'CY': 'EUR',
  'EE': 'EUR',
  'ES': 'EUR',
  'FI': 'EUR',
  'FR': 'EUR',
  'DE': 'EUR',
  'GB': 'GBP',
  'GR': 'EUR',
  'IE': 'EUR',
  'IT': 'EUR',
  'LV': 'EUR',
  'LT': 'EUR',
  'LU': 'EUR',
  'MT': 'EUR',
  'NL': 'EUR',
  'NZ': 'NZD',
  'PT': 'EUR',
  'SI': 'EUR',
  'SK': 'EUR',
  'US': 'USD'
}

export const LocationContext = React.createContext({
  country: {},
  currency: undefined,
});

export const useLocation = () => useContext(LocationContext);

export const LocationProvider = ({ children }) => {
  const [country, setCountry] = useCountry();
  const [currency, setCurrency] = useCurrency();
  const serviceUrl = siteMetadata.Location.URL;

  const fetchCountry = () => {
    if (country !== undefined) {
      return;
    }
    return window
      .fetch(`${serviceUrl}`, {
        method: 'GET',
      })
      .then(res => res.json())
      .then(res => {
        setCountry(res.country);

        if (currency !== undefined) {
          return;
        }

        if (COUNTRY_TO_CURRENCY_MAPPING[res.country] !== undefined) {
          setCurrency(COUNTRY_TO_CURRENCY_MAPPING[res.country]);
          return;
        }
        setCurrency(Currencies.default);
      });
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const refresh = async () => {
    await fetchCountry();
  };

  return (
    <LocationContext.Provider
      value={{
        country,
        currency,
        refresh
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
