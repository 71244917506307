import React, { useState, useContext } from 'react';
import mapDealData from './lib/mapDealData';
import { useApi } from '../hooks/useApi';
export const DealsContext = React.createContext({
  deals: [],
  count: 0,
  setFetchOptions: () => {},
  setDealId: () => {},
  fetchDeals: () => {},
  isLoading: false,
});
export const useDeals = () => useContext(DealsContext);

export const DealsProvider = ({ children }) => {
  const { request } = useApi();
  const [deals, setDeals] = useState([]);
  const [count, setCount] = useState(0);
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortBy, setSortBy] = useState('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterQuery, setFilterQuery] = useState(null);
  const [dealId, setDealId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDeals = async () => {
    await setIsLoading(true);
    const params = {};

    if (filterQuery) {
      params.filterQuery = filterQuery;
      params.filterBy = 'name';
    }
    if (sortDirection) {
      params.sortOrder = sortDirection;
    }
    if (sortBy) {
      params.sortBy = sortBy;
    }
    if (page && rowsPerPage) {
      params.offset = page * rowsPerPage;
      params.limit = params.offset + rowsPerPage;
    }
    try {
      const { deals, count } = await request({
        path: `/deals${dealId ? `/${dealId}` : ''}`,
        method: 'GET',
        params,
      });

      const mappedDeals = deals.map(mapDealData);
      setDeals(mappedDeals);
      setCount(count);
    } catch (error) {
      console.log('Could not load deal data');
    }
    await setIsLoading(false);
  };

  const setFetchOptions = ({
    page,
    rowsPerPage,
    sortDirection,
    sortBy,
    filterQuery,
    dealId,
  }) => {
    if (dealId !== undefined) {
      setDealId(dealId);
    }
    if (page !== undefined) {
      setPage(page);
    }

    if (rowsPerPage !== undefined) {
      setRowsPerPage(rowsPerPage);
    }

    if (sortDirection !== undefined) {
      setSortDirection(sortDirection);
    }

    if (sortBy !== undefined) {
      setSortBy(sortBy);
    }

    if (filterQuery !== undefined) {
      setFilterQuery(filterQuery);
    }
  };

  return (
    <DealsContext.Provider
      value={{
        setDealId,
        fetchDeals,
        deals,
        count,
        setFetchOptions,
        isLoading,
      }}
    >
      {children}
    </DealsContext.Provider>
  );
};
