import { siteMetadata } from '../../gatsby-config';
import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from './useAuth';
import mapDealData from './lib/mapDealData';

const profileFields = [
  [
    { id: 'headline', title: 'Personal information', type: 'headline' },
    { id: 'Title', title: 'Title', type: 'select', values: ['Mr', 'Ms', 'Mx'] },
    { id: 'First Name', title: 'First Name', type: 'string' },
    { id: 'Middle Names', title: 'Middle Name', type: 'string' },
    { id: 'Last Name', title: 'Name', type: 'string' },
    { id: 'Phone Number', title: 'Phone Number', type: 'string' },
    { id: 'Email Address', title: 'Email Address', type: 'string' },
    { id: 'Date of Birth', title: 'Date of Birth', type: 'string' },
    {
      id: 'Nationality (from your passport)',
      title: 'Nationality (from your passport)',
      type: 'select',
      values: [
        'Afghan',
        'Albanian',
        'Algerian',
        'American',
        'Andorran',
        'Angolan',
        'Antiguans',
        'Argentinean',
        'Armenian',
        'Australian',
        'Austrian',
        'Azerbaijani',
        'Bahamian',
        'Bahraini',
        'Bangladeshi',
        'Barbadian',
        'Barbudans',
        'Batswana',
        'Belarusian',
        'Belgian',
        'Belizean',
        'Beninese',
        'Bhutanese',
        'Bolivian',
        'Bosnian',
        'Brazilian',
        'British',
        'Bruneian',
        'Bulgarian',
        'Burkinabe',
        'Burmese',
        'Burundian',
        'Cambodian',
        'Cameroonian',
        'Canadian',
        'Cape Verdean',
        'Central African',
        'Chadian',
        'Chilean',
        'Chinese',
        'Colombian',
        'Comoran',
        'Congolese',
        'Costa Rican',
        'Croatian',
        'Cuban',
        'Cypriot',
        'Czech',
        'Danish',
        'Djibouti',
        'Dominican',
        'Dutch',
        'East Timorese',
        'Ecuadorean',
        'Egyptian',
        'Emirian',
        'Equatorial Guinean',
        'Eritrean',
        'Estonian',
        'Ethiopian',
        'Fijian',
        'Filipino',
        'Finnish',
        'French',
        'Gabonese',
        'Gambian',
        'Georgian',
        'German',
        'Ghanaian',
        'Greek',
        'Grenadian',
        'Guatemalan',
        'Guinea-Bissauan',
        'Guinean',
        'Guyanese',
        'Haitian',
        'Herzegovinian',
        'Honduran',
        'Hungarian',
        'I-Kiribati',
        'Icelander',
        'Indian',
        'Indonesian',
        'Iranian',
        'Iraqi',
        'Irish',
        'Israeli',
        'Italian',
        'Ivorian',
        'Jamaican',
        'Japanese',
        'Jordanian',
        'Kazakhstani',
        'Kenyan',
        'Kittian and Nevisian',
        'Kuwaiti',
        'Kyrgyz',
        'Laotian',
        'Latvian',
        'Lebanese',
        'Liberian',
        'Libyan',
        'Liechtensteiner',
        'Lithuanian',
        'Luxembourger',
        'Macedonian',
        'Malagasy',
        'Malawian',
        'Malaysian',
        'Maldivian',
        'Malian',
        'Maltese',
        'Marshallese',
        'Mauritanian',
        'Mauritian',
        'Mexican',
        'Micronesian',
        'Moldovan',
        'Monacan',
        'Mongolian',
        'Moroccan',
        'Mosotho',
        'Motswana',
        'Mozambican',
        'Namibian',
        'Nauruan',
        'Nepalese',
        'New Zealander',
        'Ni-Vanuatu',
        'Nicaraguan',
        'Nigerian',
        'Nigerien',
        'North Korean',
        'Northern Irish',
        'Norwegian',
        'Omani',
        'Pakistani',
        'Palauan',
        'Panamanian',
        'Papua New Guinean',
        'Paraguayan',
        'Peruvian',
        'Polish',
        'Portuguese',
        'Qatari',
        'Romanian',
        'Russian',
        'Rwandan',
        'Saint Lucian',
        'Salvadoran',
        'Samoan',
        'San Marinese',
        'Sao Tomean',
        'Saudi',
        'Scottish',
        'Senegalese',
        'Serbian',
        'Seychellois',
        'Sierra Leonean',
        'Singaporean',
        'Slovakian',
        'Slovenian',
        'Solomon Islander',
        'Somali',
        'South African',
        'South Korean',
        'Spanish',
        'Sri Lankan',
        'Sudanese',
        'Surinamer',
        'Swazi',
        'Swedish',
        'Swiss',
        'Syrian',
        'Taiwanese',
        'Tajik',
        'Tanzanian',
        'Thai',
        'Togolese',
        'Tongan',
        'Trinidadian or Tobagonian',
        'Tunisian',
        'Turkish',
        'Tuvaluan',
        'Ugandan',
        'Ukrainian',
        'Uruguayan',
        'Uzbekistani',
        'Venezuelan',
        'Vietnamese',
        'Welsh',
        'Yemenite',
        'Zambian',
        'Zimbabwean',
      ],
    },
  ],
  [
    { id: 'headline', title: 'Address', type: 'headline' },
    { id: 'Address Line 1', title: 'Address Line 1', type: 'string' },
    { id: 'Address Line 2', title: 'Address Line 1', type: 'string' },
    { id: 'Town', title: 'Town', type: 'string' },
    { id: 'State/County', title: 'State/County', type: 'string' },
    { id: 'ZIP / Postal Code', title: 'ZIP / Postal Code', type: 'string' },
    {
      id: 'Country of Residence',
      title: 'Country of Residence',
      type: 'select',
      values: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua & Deps',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Central African Rep',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo',
        'Congo {Democratic Rep}',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland {Republic}',
        'Israel',
        'Italy',
        'Ivory Coast',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Korea North',
        'Korea South',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar, {Burma}',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russian Federation',
        'Rwanda',
        'St Kitts & Nevis',
        'St Lucia',
        'Saint Vincent & the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome & Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad & Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe',
      ],
    },
    {
      id: 'Emergency Contact Name',
      title: 'Emergency Contact Name',
      type: 'string',
    },
    {
      id: 'Emergency Contact phone No.',
      title: 'Emergency Contact phone No.',
      type: 'string',
    },
  ],
];

/** @TODO outsource form field handling into new hook  */
export const MyBunacContext = React.createContext({
  profile: {},
  deals: [],
  fields: {},
  profileFields: [],
  request: (params) => {},
  set: () => {},
  refresh: () => {},
});
export const useMyBunac = () => useContext(MyBunacContext);

export const MyBunacProvider = ({ children }) => {
  const { token, isAuthenticated } = useAuth();
  const [profile, setProfile] = useState({});
  const [fields, setFields] = useState({});
  const [deals, setDeals] = useState([]);
  const backendUrl = siteMetadata.Auth.BACKEND_URL.replace(/\/+$/, '');

  const fetchProfile = () => {
    return window
      .fetch(`${backendUrl}/api/profile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.json())
      .then(res => {
        setProfile(res.person);
      });
  };

  const fetchDeals = async () => {
    try {
      const { deals } = await request({
        path: '/deals',
        method: 'GET',
        params: null,
      });
      setDeals(deals.map(mapDealData));
    } catch (err) {
      console.log('Could not load deal data');
    }
  };

  const fetchFields = async () => {
    const { fields } = await request({
      path: '/api/fields',
      method: 'GET',
      params: null,
    });

    setFields(fields);
  };

  const request = async ({ path, method, params }) => {
    const tmpToken = token;
    const requestParams = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (tmpToken) {
      requestParams.headers.Authorization = `Bearer ${tmpToken}`;
    }

    if (!['POST', 'PUT', 'GET', 'DELETE'].includes(method)) {
      console.error('Method needs to be one of: POST, PUT, GET, DELETE');
      return false;
    }

    if (params) {
      if (method === 'GET') {
        Object.keys(params).forEach((key, index) => {
          path += `${index ? '&' : '?'}${key}=${window.encodeURIComponent(
            params[key]
          )}`;
        });
      } else {
        try {
          params = JSON.stringify(params);
        } catch (error) {
          throw new Error(`stringifying request body failed: ${error.message}`);
        }
        requestParams.body = params;
      }
    }

    return window
      .fetch(`${backendUrl}${path}`, requestParams)
      .then(async res => {
        const response = await res.json();
        return {
          requestStatusCode: res.status,
          ...response,
        };
      });
  };

  const set = ({ id, key, value }) => {
    return window
      .fetch(`${backendUrl}/persons/${id}/attributes`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ key, value }),
      })
      .then(res => res.json());
  };

  useEffect(() => {
    fetchFields();
    if (!isAuthenticated) {
      setProfile({});
      setDeals([]);
    } else {
      refresh();
    }
  }, [isAuthenticated]);

  const refresh = async () => {
    await fetchProfile();
    await fetchDeals();
  };

  return (
    <MyBunacContext.Provider
      value={{
        profileFields,
        fields,
        profile,
        deals,
        refresh,
        set,
        request,
      }}
    >
      {children}
    </MyBunacContext.Provider>
  );
};
