import { siteMetadata } from '../../gatsby-config';
import React, { useContext, FunctionComponent } from 'react';
import { useAuth } from './useAuth';

interface IRequestParams {
  path: string;
  method: string;
  params?: { [key: string]: any };
}

interface IApiProviderProps {
  children: any;
}

type RequestParams = { [key: string]: any };

type ApiContextProps = {
  request: (params: IRequestParams) => any;
};

export const ApiContext = React.createContext<Partial<ApiContextProps>>({});

export const useApi = () => useContext(ApiContext);

export const ApiProvider: FunctionComponent<IApiProviderProps> = ({
  children,
}) => {
  const { token } = useAuth();
  const backendUrl = siteMetadata.Auth.BACKEND_URL.replace(/\/+$/, '');

  const request = async ({ path, method, params }: IRequestParams) => {
    const requestParams: RequestParams = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (token) {
      requestParams.headers.Authorization = `Bearer ${token}`;
    }

    if (!['POST', 'PUT', 'GET', 'DELETE'].includes(method)) {
      throw Error('Method needs to be one of: POST, PUT, GET, DELETE');
    }

    if (params) {
      if (method === 'GET') {
        Object.keys(params).forEach((key, index) => {
          path += `${index ? '&' : '?'}${key}=${window.encodeURIComponent(
            params[key]
          )}`;
        });
      } else {
        let body;
        try {
          body = JSON.stringify(params);
        } catch (error) {
          throw new Error(`stringifying request body failed: ${error.message}`);
        }
        requestParams.body = body;
      }
    }

    const response = await window.fetch(`${backendUrl}${path}`, requestParams);
    const responseJSON = await response.json();

    if (responseJSON.error) {
      throw new Error(responseJSON.error);
    }

    return {
      requestStatusCode: response.status,
      ...responseJSON,
    };
  };

  return (
    <ApiContext.Provider
      value={{
        request,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
