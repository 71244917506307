
let datasourceConfig = {};

try {
  // Load data source config from filesystem
  datasourceConfig = require('./.datasource');
} catch (_) { }

// Overwrite the Contentful config with environment variables if they exist
const url = process.env.SOURCE_URL || datasourceConfig.url;
const identifier = process.env.SOURCE_USER_ID || datasourceConfig.user.id;
const secret = process.env.SOURCE_USER_SECRET || datasourceConfig.user.secret;
const siteUrl = process.env.GATSBY_API_SITE_URL || 'https://bunac.org';
const authBackendUrl =
  process.env.GATSBY_API_BACKEND_URL || 'https://auth.app.bunac.org';
const locationUrl =
  process.env.GATSBY_API_LOCATION_URL || 'https://loc.bunac.org/';
const targetAddress = new URL(siteUrl);

const productionPolicy = siteUrl !== 'https://bunac.org' ?
  [{ userAgent: '*', disallow: ['/'] }] :
  [{ userAgent: '*', allow: '/', disallow: ['/status'] }]


module.exports = {
  flags: {
    PRESERVE_WEBPACK_CACHE: true,
    FAST_DEV: false,
    DEV_SSR: true,
    LAZY_IMAGES: true,
    QUERY_ON_DEMAND: true,
    PRESERVE_FILE_DOWNLOAD_CACHE: true,
    FUNCTIONS: false
  },
  siteMetadata: {
    title: 'bunac.org',
    description:
      'BUNAC are volunteer abroad & working abroad experts & have been offering working adventures worldwide to USA customers for over 50 yrs. Find out more',
    siteUrl: siteUrl,
    author: 'BUNAC Travel Services',
    Auth: {
      CLIENT_ID: 'bT7DVuWhAcWwZkK4Gsyo6Bb1Q324qkF3',
      DOMAIN: 'dev-qujgyuil.eu.auth0.com',
      AUDIENCE: 'https://dev-qujgyuil.eu.auth0.com/userinfo',
      CALLBACK_URL: `${siteUrl}/mybunac`,
      SIGNOUT_URL: `${siteUrl}/`,
      BACKEND_URL: authBackendUrl,
    },
    Stripe: {
      KEY: process.env.GATSBY_STRIPE_KEY || 'pk_test_51IRMhgIJesdxBCToa7gInx7i36D87JeJGIoqE7z18WTFs0OiPTVoOHvAcMmOIOccdLEYXZx49qp4Pg4ljT4Kk8Qc003qVUcXHM',
      PAYMENT_INTENT_URL: process.env.GATSBY_PAYMENT_INTENT_URL || 'https://ascwn3pow8.execute-api.eu-west-2.amazonaws.com/dev/payment-intent',
    },
    Location: {
      URL: locationUrl,
    },
    Paypal: {
      client: {
        sandbox:
          'AeofHsT4VkQBmv1fBUKIJTtS7Xh5nrZkiDc3gBWXFSzJam154N2xGIVhYUV7DLLFYaOWedGIJHdOCoMZ',
        production:
          'AbT6BYym8miUpfMqms4Fgo390Dj_So5IUqZmV_NVEqkSNviiyntS8i8blpwWpDLGudmLnK886_jsNMHw',
      },
      env: process.env.GATSBY_PAYPAL_ENV || 'production',
    },
  },
  plugins: [
    {
      resolve: 'gatsby-plugin-preconnect',
      options: {
        domains: ['https://static.zdassets.com', 'https://connect.facebook.net','https://www.googletagmanager.com','https://s2.adform.net'],
      },
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        output: '/',
        excludes: ['/status'],
      },
    },
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-postcss`,
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: siteUrl,
        sitemap: `${siteUrl}/sitemap-index.xml`,
        env: {
          development: {
            policy: [{ userAgent: '*', disallow: ['/'] }],
          },
          production: {
            policy: productionPolicy,
          },
        },
      },
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-alias-imports`,
      options: {
        alias: {
          '@bunac': `${__dirname}/src`
        },
        extensions: []
      }
    },
    'gatsby-plugin-styled-components',
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'static',
        path: `${__dirname}/static`,
      },
    },
    {
      resolve: 'gatsby-source-strapi',
      options: {
        apiURL: url,
        queryLimit: 10000, // Default to 100
        contentTypes: [
          'programmes',
          'continents',
          'countries',
          'categories',
          'products',
          'navigations',
          'galleries',
          'blogs',
          'blog-categories',
          'job-roles',
          'pages',
        ],
        singleTypes: ['fresh-chat'],
        loginData: {
          identifier,
          password: secret,
        },
      },
    },
    'gatsby-plugin-remove-serviceworker',
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-58J459W',
        routeChangeEventName: "page-change",
        includeInDevelopment: true,
      },
    },
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName: process.env.DEPLOYMENT_BUCKET || "test-example",
        region: process.env.AWS_REGION,
        protocol: targetAddress.protocol.slice(0, -1),
        hostname: targetAddress.hostname,
        acl: null,
        params: {},
        mergeCachingParams: true,
        generateRoutingRules: true,
        generateRedirectObjectsForPermanentRedirects: true,
        generateIndexPageForRedirect: true,
        generateMatchPathRewrites: true,
        removeNonexistentObjects: true,
      },
    },
    {
      resolve: `gatsby-plugin-s3`,
      options: {
        bucketName: process.env.DEPLOYMENT_BUCKET || "dummy",
        region: process.env.AWS_REGION,
        protocol: targetAddress.protocol.slice(0, -1),
        hostname: targetAddress.hostname,
        acl: null,
        params: {},
         mergeCachingParams: true,
        generateRoutingRules: true,
        generateRedirectObjectsForPermanentRedirects: true,
        generateIndexPageForRedirect: true,
        generateMatchPathRewrites: true,
        removeNonexistentObjects: true,
      },
    },
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: targetAddress.href.slice(0, -1),
      },
    },
  ],
};
