import mapProductData from './mapProductData';
import getCurrentStage from './getCurrentStage';
import getProgrammeStages from './getProgrammeStages';

export default deal => {
  return {
    ...deal,
    currency: deal.weighted_value_currency,
    sumForBookingsAndOptions: deal.weighted_value,
    products: deal.products.map(mapProductData(deal.weighted_value_currency)),
    activities: deal.activities || [],
    currentStage: getCurrentStage(deal),
    stages: getProgrammeStages(deal),
  };
};
