import {
  PaymentChoice,
  PaymentChoices,
} from '@bunac/components/forms/Onboarding/PaymentChoice';
import { APPLICATION_STAGES } from '@bunac/lib/applicationStages';
import {
  IDataProduct,
  IProduct,
  IProgramme,
  ProductType,
} from '@bunac/lib/model/Programme';
import { useApi } from './useApi';
import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  FunctionComponent,
  Dispatch,
  SetStateAction,
} from 'react';
import { ICurrency } from '@bunac/components/config/currency';

interface CheckoutProviderProps {
  children: any;
}

interface ConfirmPaymentParams {
  id: string;
  amount: number;
  paymentType: PaymentChoices;
  currency: ICurrency;
  level: IProduct;
}

type CheckoutContextProps = {
  isLoading: boolean;
  isAssignedLevel: boolean;
  isConfirmingPayment: boolean;
  programme: IProgramme | null;
  products: IDataProduct[];
  level: IProduct | null;
  stage: number;
  dealId: number;
  personId: number;
  email: string;
  stages: string[];
  paymentType: PaymentChoices | null;
  stripePaymentId: string | null;
  setProgramme: Dispatch<SetStateAction<IProgramme | null>>;
  setLevel: Dispatch<SetStateAction<IProduct | null>>;
  setPaymentType: Dispatch<SetStateAction<PaymentChoices | null>>;
  setStage: Dispatch<SetStateAction<number>>;
  setDealId: Dispatch<SetStateAction<number | undefined>>;
  setPersonId: Dispatch<SetStateAction<number | undefined>>;
  setEmail: Dispatch<SetStateAction<string>>;
  confirmPayment: (params: ConfirmPaymentParams) => void;
};

export const CheckoutContext = React.createContext<
  Partial<CheckoutContextProps>
>({
  personId: undefined,
  email: '',
  isLoading: false,
  isAssignedLevel: false,
  programme: null,
  products: [],
  level: null,
  stage: 0,
  dealId: undefined,
  stages: APPLICATION_STAGES,
  paymentType: null,
});

export const useCheckout = () => useContext(CheckoutContext);

export const CheckoutProvider: FunctionComponent<CheckoutProviderProps> = ({
  children,
}) => {
  const { request } = useApi();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAssignedLevel, setLevelAssigned] = useState<boolean>(false);
  const [stage, setStage] = useState<number>(0);
  const [products, setProducts] = useState<IDataProduct[]>([]);
  const [email, setEmail] = useState<string>('');
  const [dealId, setDealId] = useState<number>();
  const [personId, setPersonId] = useState<number>();
  const [programme, setProgramme] = useState<IProgramme | null>(null);
  const [level, setLevel] = useState<IProduct | null>(null);
  const [paymentType, setPaymentType] = useState<PaymentChoices | null>(null);

  const assignProgrammeLevelToDeal = useCallback(async () => {
    if (request === undefined) {
      return;
    }
    await setIsLoading(true);
    try {
      const { success } = await request({
        path: `/user/${personId}/deals/${dealId}/products/${level?.id}`,
        method: 'POST',
      });

      if (!success) {
        throw new Error(`No deal found with id ${dealId}`);
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log('Could not load deal data: ' + error);
    }
    await setLevelAssigned(true);
  }, [dealId, level, personId, request]);

  useEffect(() => {
    if (dealId === undefined || personId === undefined || level === null) {
      return;
    }
    assignProgrammeLevelToDeal();
  }, [dealId, personId, level, assignProgrammeLevelToDeal]);

  const loadProducts = useCallback(
    async (programmeId) => {
      if (request === undefined) {
        return;
      }
      await setIsLoading(true);
      try {
        const rsp = await request({
          path: '/products',
          method: 'GET',
          params: {
            programmeId,
          },
        });

        if (!rsp.success) {
          throw new Error(`No products found with id ${programmeId}`);
        }
        await setProducts(rsp.products);
        await setIsLoading(false);
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log('Could not load products data: ' + error);
      }
    },
    [request, setProducts]
  );

  useEffect(() => {
    if (programme === null || programme === undefined) {
      return;
    }
    loadProducts(programme.Pipedrive_ID);
  }, [programme, loadProducts]);

  const collectProducts = useCallback(
    (product: IProduct, type: PaymentChoices) => {
      if (programme === null) {
        return [];
      }
      const deposit = programme.Products.filter(
        (p) => p.Type === ProductType.Deposit
      ).map((p) => p.Identifier);

      if (type === PaymentChoices.DEPOSIT) {
        return products
          .filter((p) => deposit.includes(p.identifier))
          .map((p) => p.id);
      }

      const selected = programme.Products.filter(
        (p) =>
          (p.Type === ProductType.Level &&
            p.Identifier === product.Identifier) ||
          p.Type === ProductType.SecondPayment ||
          p.Type === ProductType.Deposit
      ).map((p) => p.Identifier);

      return products
        .filter((p) => selected.includes(p.identifier))
        .map((p) => p.id);
    },
    [products, programme]
  );

  const confirmPayment = useCallback(
    async (params: ConfirmPaymentParams) => {
      if (request === undefined) {
        return;
      }
      await setIsLoading(true);
      try {
        const { success } = await request({
          path: `/user/${personId}/deals/${dealId}/pay`,
          method: 'POST',
          params: {
            paymentid: params.id,
            amount: params.amount,
            paymenttype: params.paymentType,
            currency: params.currency.code,
            productids: JSON.stringify(
              collectProducts(params.level, params.paymentType)
            ),
            date: new Date().toISOString(),
          },
        });

        if (!success) {
          throw new Error(`Could not confirm payment: ${dealId}`);
        }
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log('Could not confirm payment: ' + error);
      }
    },
    [request, dealId, personId, collectProducts]
  );

  return (
    <CheckoutContext.Provider
      value={{
        isLoading,
        isAssignedLevel,
        confirmPayment,
        programme,
        setProgramme,
        products,
        level,
        setLevel,
        stage,
        setStage,
        email,
        setEmail,
        personId,
        setPersonId,
        dealId,
        setDealId,
        stages: APPLICATION_STAGES,
        paymentType,
        setPaymentType,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
