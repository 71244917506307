const programmeStageMapping = [
  {
    programmeId: 1,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Upload your supporting documents',
        pipeline_id: 2,
        stage_order_nr: 1,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload current CV'],
      },
      {
        label: 'Wait for Document Approval',
        pipeline_id: 2,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Pay Second Payment',
        pipeline_id: 2,
        stage_order_nr: 3,
        component: 'Payment',
      },
      {
        label: 'Invite your Employer',
        pipeline_id: 2,
        stage_order_nr: 4,
        component: 'InviteEmployer',
        activities: ['CUSTOMER: Invite Employer'],
      },
      {
        pipeline_id: 2,
        stage_order_nr: 5,
        component: 'Waiting',
      },
      {
        label: 'Employer approved',
        pipeline_id: 2,
        stage_order_nr: 6,
        component: 'Waiting',
        activities: [],
      },
      {
        pipeline_id: 2,
        stage_order_nr: 7,
        component: 'Waiting',
      },
      {
        label: 'Certificate Of Sponsorship (COS) issued',
        pipeline_id: 2,
        stage_order_nr: 8,
        component: 'Waiting',
        activities: [],
      },
      {
        pipeline_id: 4,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Fill out UK Entry Form',
        pipeline_id: 4,
        stage_order_nr: 2,
        component: 'UK Entry Form (?)',
        activities: ['Customer: Upload VISA Copy'],
      },
      {
        pipeline_id: 4,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        label: 'Fill out Monthly Check In Form',
        pipeline_id: 4,
        stage_order_nr: 4,
        component: 'Monthly Check in Form (?)',
        activities: [],
      },
      {
        pipeline_id: 4,
        stage_order_nr: 5,
        component: 'Waiting',
      },
    ],
  },
  {
    programmeId: 24,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Select your employer preferences',
        pipeline_id: 17,
        stage_order_nr: 1,
        component: 'EmployerPreference',
        activities: ['CUSTOMER: Choose preferences'],
      },
      {
        label: 'Upload your CV',
        pipeline_id: 17,
        stage_order_nr: 2,
        component: 'RequiredDocument',
        activities: ['CUSTOMER: Upload CV'],
      },
      {
        pipeline_id: 17,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        pipeline_id: 17,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Book Visa Appointement',
        pipeline_id: 17,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
      {
        label: 'Book travel',
        pipeline_id: 17,
        stage_order_nr: 6,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 17,
        stage_order_nr: 7,
        component: 'Waiting',
      },
      {
        pipeline_id: 17,
        stage_order_nr: 8,
        component: 'Waiting',
      },
    ],
  },
  {
    programmeId: 3,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 18,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        pipeline_id: 18,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Confirm your travel plans',
        pipeline_id: 18,
        stage_order_nr: 3,
        component: 'Manual Step Screen (?)',
        activities: [''],
      },
      {
        pipeline_id: 17,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 18,
        stage_order_nr: 5,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 8,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 33,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        pipeline_id: 33,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Confirm your travel plans',
        pipeline_id: 33,
        stage_order_nr: 3,
        component: 'Manual Step Screen (?)',
        activities: [''],
      },
      {
        pipeline_id: 33,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 33,
        stage_order_nr: 5,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 22,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Confirm your travel plans',
        pipeline_id: 19,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 19,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 19,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Final Payment'],
      },
      {
        pipeline_id: 19,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 19,
        stage_order_nr: 5,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 25,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Confirm your travel plans',
        pipeline_id: 20,
        stage_order_nr: 1,
        component: 'Manual Step Screen (?)',
        activities: [''],
      },
      {
        pipeline_id: 20,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 20,
        stage_order_nr: 3,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    /// programmeId: Work South Africa,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        label: 'Confirm your travel plans',
        pipeline_id: 22,
        stage_order_nr: 1,
        component: 'Manual Step Screen (?)',
        activities: ['Customer: provide travel details'],
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 22,
        stage_order_nr: 3,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 33,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Confirm your travel plans',
        pipeline_id: 23,
        stage_order_nr: 1,
        component: 'EmployerPreference',
        activities: [
          'CUSTOMER: promting to select orientation date and hostel preference',
        ],
      },
      {
        pipeline_id: 23,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 23,
        stage_order_nr: 3,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 'Intern USA Ultimate',
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Continue with the Intern Group',
        pipeline_id: 13,
        stage_order_nr: 1,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 'Intern USA Essential',
    stages: [
      {
        label: 'Interest Registered',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level and Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
    ],
  },
  {
    programmeId: 6,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Continue with i-to-i',
        pipeline_id: 27,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 21,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Continue with i-to-i',
        pipeline_id: 31,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 5,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Continue with i-to-i',
        pipeline_id: 32,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 19,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Confirm your travel plans',
        pipeline_id: 28,
        stage_order_nr: 1,
        component: 'Manual Step (?)',
        activities: [''],
      },
      {
        pipeline_id: 38,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        pipeline_id: 28,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        label: 'Pay final payment',
        pipeline_id: 28,
        stage_order_nr: 4,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 4,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "United Through Sport"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 10,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Wait for Application Form and send it back',
        pipeline_id: 9,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 9,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Wait for InternNZ to confirm Application',
        pipeline_id: 9,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 9,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 9,
        stage_order_nr: 5,
        component: 'Payment',
        activities: ['Customer: Final Payment'],
      },
      {
        pipeline_id: 9,
        stage_order_nr: 6,
        component: 'Waiting',
      },
    ],
  },
  {
    programmeId: 9,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Wait for Application Form and send it back',
        pipeline_id: 30,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 30,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Wait for InternNZ to confirm Application',
        pipeline_id: 30,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 30,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 30,
        stage_order_nr: 5,
        component: 'Payment',
        activities: ['Customer: Final Payment'],
      },
      {
        pipeline_id: 30,
        stage_order_nr: 6,
        component: 'Waiting',
      },
    ],
  },
  {
    programmeId: 2,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "United Through Sport"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 20,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "United Through Sport"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 18,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "United Through Sport"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 7,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "United Through Sport"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 29,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Wait for TIG Form',
        pipeline_id: 13,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        label: 'Send Form to Partner',
        pipeline_id: 13,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Proceed with Partner',
        pipeline_id: 13,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 23,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "United Through Sport"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 7,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Manual (?)',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "GVI"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 23,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Manual (?)',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "GVI"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 20,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "GVI"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 18,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 26,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Upload the required Documents',
        pipeline_id: 26,
        stage_order_nr: 2,
        component: 'RequiredDocuments',
        activities: ['Customer: Upload required documents'],
      },
      {
        label: 'Please provide your travel details',
        pipeline_id: 26,
        stage_order_nr: 3,
        component: 'Waiting',
        activities: ['Customer: Provide travel details'],
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 26,
        stage_order_nr: 4,
        component: 'Payment',
        activities: [''],
      },
      {
        label: 'Continue with "GVI"',
        pipeline_id: 26,
        stage_order_nr: 5,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 13,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Wait for Application Form and send it back',
        pipeline_id: 36,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 36,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        pipeline_id: 36,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        pipeline_id: 36,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Make Final Payment',
        pipeline_id: 36,
        stage_order_nr: 5,
        component: 'Payment',
        activities: 'Customer: 2nd Payment',
      },
      {
        pipeline_id: 36,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        pipeline_id: 36,
        stage_order_nr: 7,
        component: 'Payment',
        activities: 'Customer: final payment',
      },
      {
        label: 'Provide Information on Visa, Accommodation and Flights',
        pipeline_id: 36,
        stage_order_nr: 8,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 36,
        stage_order_nr: 9,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 17,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Wait for Application Form and send it back',
        pipeline_id: 38,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 38,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        pipeline_id: 38,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        pipeline_id: 38,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Make Final Payment',
        pipeline_id: 38,
        stage_order_nr: 5,
        component: 'Payment',
        activities: 'Customer: 2nd Payment',
      },
      {
        pipeline_id: 38,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        pipeline_id: 38,
        stage_order_nr: 7,
        component: 'Payment',
        activities: 'Customer: final payment',
      },
      {
        label: 'Provide Information on Visa, Accommodation and Flights',
        pipeline_id: 38,
        stage_order_nr: 8,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 38,
        stage_order_nr: 9,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 27,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Wait for Application Form and send it back',
        pipeline_id: 39,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 39,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        pipeline_id: 39,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        pipeline_id: 39,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Make Final Payment',
        pipeline_id: 39,
        stage_order_nr: 5,
        component: 'Payment',
        activities: 'Customer: 2nd Payment',
      },
      {
        pipeline_id: 39,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        pipeline_id: 39,
        stage_order_nr: 7,
        component: 'Payment',
        activities: 'Customer: final payment',
      },
      {
        label: 'Provide Information on Visa, Accommodation and Flights',
        pipeline_id: 39,
        stage_order_nr: 8,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 39,
        stage_order_nr: 9,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 12,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Wait for Application Form and send it back',
        pipeline_id: 37,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        pipeline_id: 37,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        pipeline_id: 37,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        pipeline_id: 37,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Make Final Payment',
        pipeline_id: 37,
        stage_order_nr: 5,
        component: 'Payment',
        activities: 'Customer: 2nd Payment',
      },
      {
        pipeline_id: 37,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        label: 'Pay final Payment',
        pipeline_id: 37,
        stage_order_nr: 7,
        component: 'Payment',
        activities: 'Customer: final payment',
      },
      {
        label: 'Provide Information on Visa, Accommodation and Flights',
        pipeline_id: 37,
        stage_order_nr: 8,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 37,
        stage_order_nr: 9,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 15,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Wait for Welcome Call',
        pipeline_id: 25,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
      {
        label: 'Pay Second Payment',
        pipeline_id: 25,
        stage_order_nr: 2,
        component: 'Payment',
      },
      {
        pipeline_id: 25,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        label: 'Wait for your Interview to be arranged',
        pipeline_id: 25,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 25,
        stage_order_nr: 5,
        component: 'Waiting',
      },
      {
        label: 'Pay final Payment',
        pipeline_id: 25,
        stage_order_nr: 6,
        component: 'Payment',
        activities: 'Customer: final payment',
      },
      {
        label: 'Provide Travel Details',
        pipeline_id: 25,
        stage_order_nr: 7,
        component: 'Waiting',
      },
      {
        label: 'Ready to Travel',
        pipeline_id: 25,
        stage_order_nr: 9,
        component: 'Waiting',
        activities: [''],
      },
    ],
  }, /// Direct
  {
    programmeId: 28,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 5,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Book your Interview',
        pipeline_id: 5,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Pay Second Payment',
        pipeline_id: 5,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Make 2nd payment'],
      },
      {
        label: 'Submit your Beacon Application',
        pipeline_id: 5,
        stage_order_nr: 4,
        component: 'RequiredDocuments',
        activities: ['Customer: Submit Beacon Applicaiton'],
      },
      {
        pipeline_id: 5,
        stage_order_nr: 5,
        component: 'Waiting',
      },
      {
        pipeline_id: 5,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        pipeline_id: 5,
        stage_order_nr: 7,
        component: 'Waiting',
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 5,
        stage_order_nr: 8,
        component: 'Payment',
        activities: ['Customer: make final payment'],
      },
      {
        pipeline_id: 5,
        stage_order_nr: 9,
        component: 'Waiting',
      },
      {
        label: 'Book your Visa Appointment',
        pipeline_id: 5,
        stage_order_nr: 10,
        component: 'Manual (?)',
        activities: [''],
      },
      {
        pipeline_id: 5,
        stage_order_nr: 11,
        component: 'Waiting',
      },
      {
        label: 'Book your Orientation',
        pipeline_id: 5,
        stage_order_nr: 12,
        component: 'Orientation Date Form (?)',
        activities: [''],
      },
      {
        label: 'Provide your Flight Information',
        pipeline_id: 5,
        stage_order_nr: 13,
        component: 'Flight',
        activities: ['CUSTOMER: Provide Flight Form'],
      },
      {
        pipeline_id: 5,
        stage_order_nr: 14,
        component: 'Waiting',
      },
      {
        label: 'Flights Booked',
        pipeline_id: 5,
        stage_order_nr: 15,
        component: '',
        activities: [''],
      },
    ],
  }, /// Returner
  {
    programmeId: 28,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 6,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Book your Interview',
        pipeline_id: 5,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        label: 'Pay Second Payment',
        pipeline_id: 6,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: 2nd Payment'],
      },
      {
        label: 'Complete your Beacon Application',
        pipeline_id: 6,
        stage_order_nr: 4,
        component: 'RequiredDocuments',
        activities: ['Customer: Completes Beacon Applicaiton'],
      },
      {
        pipeline_id: 6,
        stage_order_nr: 5,
        component: 'Waiting',
      },
      {
        pipeline_id: 6,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        pipeline_id: 6,
        stage_order_nr: 7,
        component: 'Waiting',
      },
      {
        label: 'Pay Final Payment',
        pipeline_id: 6,
        stage_order_nr: 8,
        component: 'Payment',
        activities: ['Customer: final payment'],
      },
      {
        pipeline_id: 6,
        stage_order_nr: 9,
        component: 'Waiting',
      },
      {
        pipeline_id: 6,
        stage_order_nr: 10,
        component: 'Waiting',
      },
      {
        label: 'Book your Visa Appointment',
        pipeline_id: 6,
        stage_order_nr: 11,
        component: 'Manual (?)',
        activities: [''],
      },
      {
        label: 'Book your Orientation',
        pipeline_id: 6,
        stage_order_nr: 12,
        component: 'Orientation Date Form (?)',
        activities: ['Customer: Select Orientation'],
      },
      {
        label: 'Provide your Flight Information',
        pipeline_id: 6,
        stage_order_nr: 13,
        component: 'Flight',
        activities: ['CUSTOMER: Submit Flight Form'],
      },
      {
        pipeline_id: 6,
        stage_order_nr: 14,
        component: 'Waiting',
      },
      {
        pipeline_id: 6,
        stage_order_nr: 15,
        component: 'Waiting',
      },
      {
        label: 'Flights Booked',
        pipeline_id: 5,
        stage_order_nr: 16,
        component: '',
        activities: [''],
      },
    ],
  }, /// First Timer
  {
    programmeId: 28,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Book Regional Interview',
        pipeline_id: 7,
        stage_order_nr: 1,
        component: 'Manual (?)',
        activities: [''],
      },
      {
        pipeline_id: 7,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        pipeline_id: 7,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        label: 'Pay Second Payment',
        pipeline_id: 7,
        stage_order_nr: 4,
        component: 'Payment',
        activities: ['Customer: Make 2nd Payment'],
      },
      {
        label: 'Submit your Beacon Application',
        pipeline_id: 7,
        stage_order_nr: 5,
        component: 'Manual (?)',
        activities: [''],
      },
      {
        pipeline_id: 7,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        pipeline_id: 7,
        stage_order_nr: 7,
        component: 'Waiting',
      },
      {
        pipeline_id: 7,
        stage_order_nr: 8,
        component: 'Waiting',
      },
      {
        label: 'Pay final Payment',
        pipeline_id: 7,
        stage_order_nr: 9,
        component: 'Payment',
        activities: ['Customer: Make final Payment'],
      },
      {
        pipeline_id: 7,
        stage_order_nr: 10,
        component: 'Waiting',
      },
      {
        pipeline_id: 7,
        stage_order_nr: 11,
        component: 'Waiting',
      },
      {
        label: 'Book your Visa Appointment',
        pipeline_id: 7,
        stage_order_nr: 12,
        component: 'Manual (?)',
        activities: [''],
      },
      {
        label: 'Book your Orientation',
        pipeline_id: 7,
        stage_order_nr: 13,
        component: 'Orientation Date Form (?)',
        activities: [''],
      },
      {
        label: 'Provide your Flight Information',
        pipeline_id: 7,
        stage_order_nr: 13,
        component: 'Flight',
        activities: ['CUSTOMER: Provide Flight Form'],
      },
    ],
  },
  {
    programmeId: 31,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Login to your CCUSA Footprints Account',
        pipeline_id: 15,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 26,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 16,
        stage_order_nr: 1,
        component: 'Waiting',
      },
      {
        label: 'Book your Interview',
        pipeline_id: 16,
        stage_order_nr: 2,
        component: 'SkypeInterview',
        activities: ['Customer: books a skype interview date and time'],
      },
      {
        pipeline_id: 16,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        label: 'Pay second payment',
        pipeline_id: 16,
        stage_order_nr: 4,
        component: 'Payment',
        activities: ['Customer: pays second payment'],
      },
      {
        pipeline_id: 16,
        stage_order_nr: 5,
        component: 'Waiting',
      },
      {
        label: 'Hired',
        pipeline_id: 16,
        stage_order_nr: 6,
        component: '',
        activities: ['Customer: Receives DS2019 and applies for the J1 visa'],
      },
    ],
  },
  {
    programmeId: 16,
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Enter the IEC Visa Pool and Inform Bunac',
        pipeline_id: 10,
        stage_order_nr: 1,
        component: 'Manual (?)',
        activities: [''],
      },
      {
        label: 'Pay Balance Payment',
        pipeline_id: 10,
        stage_order_nr: 2,
        component: 'Customer: Final Payment',
      },
      {
        label: 'Upload CV and Cover Letter',
        pipeline_id: 10,
        stage_order_nr: 3,
        component: 'RequiredDocuments',
        activities: 'Customer: Upload CV',
        activities: 'Customer: Upload covering letter',
      },
      {
        label: 'Provide Employer Preferences',
        pipeline_id: 10,
        stage_order_nr: 4,
        component: 'EmployerPreference',
        activities: 'Customer: Upload Employer Preferences',
      },
      {
        pipeline_id: 10,
        stage_order_nr: 5,
        component: 'Waiting',
      },
      {
        pipeline_id: 10,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        label: 'Book your Travel',
        pipeline_id: 10,
        stage_order_nr: 7,
        component: 'Manual (?)',
        activities: ['Customer: books travel'],
      },
      {
        label: 'Book Insurance',
        pipeline_id: 10,
        stage_order_nr: 8,
        component: 'AddOns',
        activities: ['Customer: books Insurance'],
      },
      {
        pipeline_id: 10,
        stage_order_nr: 9,
        component: 'Waiting',
      },
      {
        pipeline_id: 10,
        stage_order_nr: 10,
        component: 'Waiting',
      },
      {
        label: 'Booking Confirmed',
        pipeline_id: 10,
        stage_order_nr: 11,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 'Work Canada over 30',
    stages: [
      {
        label: 'Complete your booking',
        pipeline_id: 3,
        stage_order_nr: 1,
        component: 'FullApplication',
        activities: ['Customer: Full Registration'],
      },
      {
        label: 'Choose your Level',
        pipeline_id: 3,
        stage_order_nr: 2,
        component: 'ProgrammeLevel',
        activities: ['This component does not support activities'],
      },
      {
        label: 'Pay the Deposit',
        pipeline_id: 3,
        stage_order_nr: 3,
        component: 'Payment',
        activities: ['Customer: Deposit'],
      },
      {
        pipeline_id: 3,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        label: 'Enter the IEC Visa Pool and Inform Bunac',
        pipeline_id: 35,
        stage_order_nr: 1,
        component: 'Waiting',
        activities: [
          'Customer: Enters the IEC and informs BUNAC when receives ITA',
        ],
      },
      {
        pipeline_id: 35,
        stage_order_nr: 2,
        component: 'Waiting',
      },
      {
        pipeline_id: 35,
        stage_order_nr: 3,
        component: 'Waiting',
      },
      {
        pipeline_id: 35,
        stage_order_nr: 4,
        component: 'Waiting',
      },
      {
        pipeline_id: 35,
        stage_order_nr: 5,
        component: 'Waiting',
      },
      {
        pipeline_id: 35,
        stage_order_nr: 6,
        component: 'Waiting',
      },
      {
        label: 'Book your Travel',
        pipeline_id: 35,
        stage_order_nr: 7,
        component: 'Waiting',
        activities: ['Customer: books travel'],
      },
      {
        label: 'Book your Insurance',
        pipeline_id: 35,
        stage_order_nr: 8,
        component: 'AddOns',
        activities: ['Customer: books Insurance'],
      },
      {
        pipeline_id: 35,
        stage_order_nr: 9,
        component: 'Waiting',
      },
      {
        pipeline_id: 35,
        stage_order_nr: 10,
        component: 'Waiting',
      },
      {
        label: 'Booking Confirmed',
        pipeline_id: 35,
        stage_order_nr: 11,
        component: '',
        activities: [''],
      },
    ],
  },
  {
    programmeId: 'default',
    stages: [],
  },
];

export default application => {
  const id =
    !application ||
    !application.programmeDetails ||
    !application.programmeDetails.id
      ? 'default'
      : application.programmeDetails.id;
  let programmeStages = programmeStageMapping.find(
    ({ programmeId }) => programmeId === id
  );
  if (!programmeStages) {
    programmeStages = programmeStageMapping.find(
      ({ programmeId }) => programmeId === 'default'
    );
  }
  const { stages } = programmeStages;
  const currentStepNumber = application
    ? stages.findIndex(
        stage =>
          stage.pipeline_id === application.pipeline_id &&
          stage.stage_order_nr === application.stage_order_nr
      )
    : 0;

  const stagesWithState = stages.map((stage, stepNumber) => {
    let status = 'pending';
    if (stepNumber < currentStepNumber) {
      status = 'finished';
    }
    if (
      stepNumber === currentStepNumber ||
      (currentStepNumber === -1 && stepNumber === 0)
    ) {
      status = 'waiting';
    }

    return {
      ...stage,
      status,
    };
  });

  return stagesWithState;
};
