import styled from 'styled-components';

export const FormTitle = styled.h2`
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 32px;
  }
`;
