export interface ICurrency {
  code: string;
  symbol: string;
}

const Currencies = {
  default: 'GBP',
  supported: [
    { code: 'USD', symbol: '$' },
    { code: 'GBP', symbol: '£' },
    { code: 'EUR', symbol: '€' },
    { code: 'AUD', symbol: '$' },
    { code: 'NZD', symbol: '$' },
  ],
};

export enum CurrencyValues {
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
  AUD = 'AUD',
  NZD = 'NZD',
}

export const CURRENCY_SYMBOLS = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  AUD: '$',
  NZD: '$',
};

export const currency = (select: string) =>
  Currencies.supported.filter(({ code }) => code === select)[0];

export const DEFAULT_CURRENCY = currency(Currencies.default);

export { Currencies };
